<template>
  <div style="padding: 0.5rem 0.5rem 10rem 0.5rem">
    <div class="mb-50 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
      <button-loading-widget
        text="ย้อนกลับ"
        :to="{ name: 'rate-dckk-sale-view' }"
        :loading="pageLoading"
        variant="primary"
        icon="ChevronLeftIcon"
        size="md"
      />
      <div class="text-right mt-1 mt-sm-0">
        <h1 class="mb-0">ตารางผ่อนดาวน์</h1>
        <b-skeleton
          v-if="pageLoading"
          width="50%"
          class="ml-auto"
        />
        <div v-else-if="financeList.length > 0">
          <h5>{{ financeList[0].finance_car_data_name_en }} ({{ financeList[0].finance_car_data_name_th }})</h5>
        </div>
        <div v-else>
          <h5>ไม่พบข้อมูล</h5>
        </div>
      </div>
    </div>
    <b-card
      v-for="finance in financeList"
      :key="finance.id"
      no-body
      class="overflow-hidden mb-50"
    >
      <div class="p-1 py-50 d-flex align-items-center justify-content-between">
        <div>
          <h4
            class="m-0 font-weight-bolder"
            :style="{ color: finance.finance_color }"
          >
            {{ finance.finance_name }}
            <small>({{ finance.car_model }}) ({{ `${finance.car_size}`.toUpperCase() }})</small>
          </h4>
          <small class="mr-1">Code : {{ finance.finance_car_code }}</small>
          <small>เลขตัวถัง : {{ finance.finance_car_data_frame_number }}</small>
        </div>
        <button-loading-widget
          text="คำนวณ"
          variant="primary"
          size="sm"
          :loading="currentIdLoading === finance.finance_data_id"
          @click="getExtraFinanceCalculate(finance)"
        />
      </div>
      <b-table-simple
        small
        class="mb-0"
      >
        <b-thead class="text-light">
          <b-tr>
            <b-th
              colspan="1"
              rowspan="2"
              class="align-middle text-center p-0"
              :style="{ backgroundColor: finance.finance_color }"
            >
              เงินสด
            </b-th>
            <b-th
              colspan="1"
              rowspan="2"
              class="align-middle border-left text-center"
              :style="{ backgroundColor: finance.finance_color }"
            >
              %
            </b-th>
            <b-th
              colspan="1"
              rowspan="2"
              class="align-middle text-center"
              :style="{ backgroundColor: finance.finance_color }"
            >
              ออกรถ
            </b-th>
            <b-th
              colspan="1"
              rowspan="2"
              class="align-middle text-center"
              :style="{ backgroundColor: finance.finance_color }"
            >
              ยอดจัด
            </b-th>
            <b-th
              v-if="`${finance.car_size}`.toUpperCase() === 'L'"
              colspan="1"
              rowspan="2"
              class="align-middle text-center"
              :style="{ backgroundColor: finance.finance_color }"
            >
              ดอกเบี้ย
            </b-th>
            <b-th
              colspan="100%"
              class="text-center border py-50"
              :style="{ backgroundColor: finance.finance_color, borderWidth: '1px' }"
            >
              จำนวนงวด(เดือน)/เงินที่ต้องผ่อน
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              v-for="(row, rowIndex) in finance.finance_car_item_interest_rate_json[0].selection_of_installments"
              :key="rowIndex"
              class="text-center border-left border-right p-0 pb-50"
              :style="{ backgroundColor: finance.finance_color }"
            >
              <p class="mb-0">{{ row.period }}</p>
              <small v-if="`${finance.car_size}`.toUpperCase() !== 'L'">({{ Number(row.interest) }}%)</small>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td
              rowspan="100%"
              class="text-center border-right p-0 font-weight-bolder"
            >
              {{ gFormatNumberToCurrency(finance.finance_car_item_price) }}
            </b-td>
          </b-tr>
          <b-tr
            v-for="(row, rowIndex) in finance.finance_car_item_interest_rate_json"
            :key="rowIndex"
            :style="{ backgroundColor: rowIndex % 2 === 0 ? '' : '#f8f8f8' }"
          >
            <b-td class="text-center p-0 font-weight-bolder"> {{ row.percent_down }} </b-td>
            <b-td class="text-center p-0 font-weight-bolder">
              {{ gFormatNumberToCurrency(row.down_payment) }}
              <!-- <p class="mb-0">
                {{ (finance.finance_car_item_interest_rate_json[0].finance_loan_amount * row.percent_down) / 100 }}
              </p> -->
              <!-- {{
                gFormatNumberToCurrency(
                  testRateCal(finance.finance_car_item_interest_rate_json[0].finance_loan_amount, row.percent_down),
                )
              }} -->
            </b-td>
            <b-td class="text-center p-0 font-weight-bolder">
              {{ gFormatNumberToCurrency(row.finance_loan_amount) }}
              <!-- {{
                gFormatNumberToCurrency(
                  testYodJudCal(
                    finance.finance_car_item_interest_rate_json[0].finance_loan_amount,
                    testRateCal(
                      finance.finance_car_item_interest_rate_json[0].finance_loan_amount,
                      row.percent_down,
                    ),
                  ),
                )
              }} -->
            </b-td>
            <b-td
              v-if="`${finance.car_size}`.toUpperCase() === 'L'"
              class="text-center p-0 font-weight-bolder"
              >{{ row.interest_rate }} %</b-td
            >
            <b-td
              v-for="(period, periodIndex) in row.selection_of_installments"
              :key="periodIndex"
              class="text-center border-left p-0 py-50 font-weight-bolder"
            >
              <!-- <p
                v-if="period.dummy"
                class="m-0 mb-1"
              >
                raw : {{ gFormatNumberToCurrency(period.dummy) }}
                <small v-if="period.dummy_round">{{ period.dummy_round }}</small>
              </p> -->

              <b
                v-if="period.period === 48"
                class="text-danger font-weight-bolder"
              >
                {{ gFormatNumberToCurrency(period.installment) }}
              </b>
              <span
                v-else
                class="font-weight-bolder"
              >
                {{ gFormatNumberToCurrency(period.installment) }}
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot v-if="finance.extraItemCalculated">
          <b-tr
            :style="{ backgroundColor: finance.finance_color }"
            class="text-light"
          >
            <b-td class="text-center py-50 border-right font-weight-bolder"> ตารางผ่อนที่ลูกค้าเลือก </b-td>
            <b-td class="text-center font-weight-bolder"> {{ finance.extraItemCalculated.percent_down }} </b-td>
            <b-td class="text-center font-weight-bolder">{{
              gFormatNumberToCurrency(finance.extraItemCalculated.down_payment)
            }}</b-td>
            <b-td class="text-center font-weight-bolder">{{
              gFormatNumberToCurrency(finance.extraItemCalculated.finance_loan_amount)
            }}</b-td>
            <b-td
              v-if="`${finance.car_size}`.toUpperCase() === 'L'"
              class="text-center font-weight-bolder"
              >{{ finance.extraItemCalculated.interest_rate }}%</b-td
            >
            <b-td
              v-for="(period, periodIndex) in finance.extraItemCalculated.selection_of_installments"
              :key="periodIndex"
              class="text-center border-left px-1 py-2 font-weight-bolder"
            >
              {{ gFormatNumberToCurrency(period.installment) }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <!-- <pre>{{ finance }}</pre> -->
    </b-card>

    <b-modal
      ref="refModalCalculate"
      ok-only
      centered
      ok-title="คำนวณ"
      title="คำนวนเรท"
      title-class="font-weight-bolder"
      @ok="calculateFinanceRate"
    >
      <div v-if="formCalculate.finance">
        <p class="font-weight-bolder">ชื่อไทย : {{ formCalculate.finance.finance_car_data_name_th }}</p>
        <p class="font-weight-bolder">ชื่ออังกฤษ : {{ formCalculate.finance.finance_car_data_name_en }}</p>
        <p class="font-weight-bolder">
          ราคา : {{ gFormatNumberToCurrency(formCalculate.finance.finance_car_item_price) }}
        </p>
      </div>
      <b-form-group
        label="เงินดาวน์"
        class="font-weight-bolder"
      >
        <input-currency-widget
          v-model="formCalculate.price"
          placeholder="โปรดระบุ"
        />
      </b-form-group>
      <!-- <pre>{{ formCalculate }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      draftData: [
        {
          car_number: 'car_number',
          cash: 123,
          code_car: 'code_car',
          code_name: 'code_name',
          finance_total: 200,
          finance_color: '#2196f3',
          id: 'id-1',
          period_datas: [
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
                {
                  installment: 0,
                  period: 42,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
                {
                  installment: 0,
                  period: 42,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
                {
                  installment: 0,
                  period: 42,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
                {
                  installment: 0,
                  period: 42,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
                {
                  installment: 0,
                  period: 42,
                },
              ],
            },
          ],
          extraItemCalculated: {
            down_payment: 0,
            finance_loan_amount: 0,
            interest_rate: 0,
            percent_down: 0,
            selection_of_installments: [
              {
                installment: 0,
                period: 12,
              },
              {
                installment: 0,
                period: 18,
              },
              {
                installment: 0,
                period: 24,
              },
              {
                installment: 0,
                period: 30,
              },
              {
                installment: 0,
                period: 36,
              },
              {
                installment: 0,
                period: 42,
              },
            ],
          },
        },
        {
          car_number: 'car_number',
          cash: 123,
          code_car: 'code_car',
          code_name: 'code_name',
          finance_total: 200,
          finance_color: '#15c755',
          id: 'id-2',
          period_datas: [
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
              ],
            },
            {
              down_payment: 0,
              finance_loan_amount: 0,
              interest_rate: 0,
              percent_down: 0,
              selection_of_installments: [
                {
                  installment: 0,
                  period: 12,
                },
                {
                  installment: 0,
                  period: 18,
                },
                {
                  installment: 0,
                  period: 24,
                },
                {
                  installment: 0,
                  period: 30,
                },
                {
                  installment: 0,
                  period: 36,
                },
              ],
            },
          ],
          extraItemCalculated: null,
        },
      ],
      financeList: [],
      pageLoading: false,
      currentIdLoading: null,
      formCalculate: {
        price: 0,
        finance: null,
      },
    }
  },
  created() {
    const { id } = this.$route.params
    this.getRateDataById(id)
  },
  methods: {
    async getRateDataById(id) {
      this.pageLoading = true
      const resp = await this.$http.get(`api/v1/car/financeInfo/${id}`).catch(() => null)
      // console.log('getRateDataById', resp)
      if (resp && resp.code === 200) {
        this.financeList = [...resp.data].map(v => ({ ...v, extraItemCalculated: null }))
      }
      this.pageLoading = false
    },
    getExtraFinanceCalculate(finance) {
      this.formCalculate.finance = finance
      this.$refs.refModalCalculate.show()
      // console.log('getExtraFinanceCalculate', finance)
    },
    async calculateFinanceRate(bvEvent) {
      // bvEvent.preventDefault()

      const { finance_data_id, finance_car_item_price, finance_car_item_interest_rate_json } =
        this.formCalculate.finance
      const { price } = this.formCalculate
      const { id } = this.$route.params
      // console.log(finance_car_item_interest_rate_json)
      // return
      if (
        price > Number(finance_car_item_price) ||
        price < 0 ||
        price < finance_car_item_interest_rate_json[0].down_payment ||
        price > finance_car_item_interest_rate_json[0].finance_loan_amount
      ) {
        this.gDisplayToast('ราคาที่กรอกมาไม่ถูกต้อง', '', 'warning')
        return
      }
      this.currentIdLoading = this.formCalculate.finance.finance_data_id
      const requestData = {
        finance_car_data_id: id,
        finance_data_id,
        down_payment: price,
        objs: { ...this.formCalculate.finance },
      }

      const resp = await this.$http
        .post('api/v1/car/financeInfo/finance-calcul', requestData, { useAlert: { successEnable: false } })
        .catch(() => null)

      // console.log('calculateFinanceRate', resp)
      if (resp && resp.code === 200) {
        const extraItemCalculated = { ...resp.data[0] }
        this.financeList = [...this.financeList].map(v =>
          // eslint-disable-next-line camelcase
          v.finance_data_id === finance_data_id ? { ...v, extraItemCalculated } : v,
        )
      }

      this.resetForm()
      this.currentIdLoading = null
    },
    resetForm() {
      this.formCalculate = { price: 0, finance: null }
    },
    testRateCal(yodJudFirst, percentDown, defaultDown = 0) {
      if (percentDown === 0) return 0 + defaultDown
      const yodJudCal = (yodJudFirst * percentDown) / 100

      if (yodJudCal % 100 === 0) {
        return yodJudCal + defaultDown
      }

      const scrap = yodJudCal % 100 // หาเศษหลัก 10

      return yodJudCal - scrap + 100 + defaultDown
    },
    testYodJudCal(yodJudFirst, priceDown) {
      return yodJudFirst - priceDown
    },
  },
}
</script>

<style lang="scss"></style>
